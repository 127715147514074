import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  pricingPackByPackageId: [],
  operationType: undefined,
  packageObj: null,
  packageActivites: [],
  upcomingClasses: [],
  isLoading: false,
  packages: [],
  packagePricingPacks: [],
  sellingFast: [],
  myBookings: {
    upcomingBookings: [],
    pastBookings: []
  },
  availabilityMapForSlots: undefined,
  redirectLink: undefined
}

export const parentSlice = createSlice({
  name: 'parent',
  initialState: { ...initialState },
  reducers: {
    sagaSuccess: (state, action) => {
      if (action.payload) {
        Object.assign(state, action.payload);
      }
    },
    sagaFailed: (state, action) => {
      state.operationType = undefined;
      state.isLoading = false;
    },
    getPackageById: (state, action) => {
      state.isLoading = true;
    },
    fetchPackageActivites: (state, action) => {
      state.isLoading = true;
    },
    fetchUpcomingClasses: (state, action) => {
      state.isLoading = true;
    },
    getPricePackByPackageId: (state, action) => {
      state.isLoading = true;
    },
    requestApproval: (state, action) => {
      state.isLoading = true
    },
    getAllPackages: (state, action) => {
      state.isLoading = true
    },
    fetchPricingPacksForAllPackages: (state, action) => {
      state.isLoading = true
    },
    getSellingFast: (state, action) => {
      state.isLoading = true
    },
    updateWishlist: (state, action) => {
      state.isLoading = true
    },
    getMyBookings: (state, action) => {
      state.isLoading = true
    },
    getNotificationSettings: (state, action) => {
      state.isLoading = true
    },
    changeNotificationSettings: (state, action) => {
      state.isLoading = true
    },
    leaveReview: (state, action) => {
      state.isLoading = true
    },
    getAvailabilityMapForSlots: (state, action) => {
      state.isLoading = true
    },
    payNow: (state, action) => {
      state.isLoading = true
    },
  }
})

export const {
  sagaSuccess,
  getPackageById,
  fetchPackageActivites,
  fetchUpcomingClasses,
  getPricePackByPackageId,
  requestApproval,
  getAllPackages,
  fetchPricingPacksForAllPackages,
  getSellingFast,
  updateWishlist,
  getMyBookings,
  getNotificationSettings,
  changeNotificationSettings,
  leaveReview,
  getAvailabilityMapForSlots,
  payNow
} = parentSlice.actions

export default parentSlice.reducer