import "firebase/compat/storage";
import { call, takeLatest, put } from "redux-saga/effects";
import { callApi } from "../../util/superAgentUtil";
import actions from "../actionType";
import { notification } from "../../util/notification";

function* addClassroom(request) {
  try {
    const { firebase, reqObj } = request.payload;
    let endpoint = `bookingApi/classroom`
    let response = yield call(callApi, firebase, "post", endpoint, reqObj);
    if (response.status == 200) {
      notification("success", response.body.message)
      yield put({
        type: actions.SETTING_SAGA_SUCCESS,
        payload: { isLoading: false, operationType: "FETCH_CLASSROOMS" }
      });
    }
    else {
      yield put({ type: actions.SETTING_SAGA_FAILED });
    }
  } catch (err) {
    yield put({
      type: actions.SETTING_SAGA_FAILED
    });
    console.log("failed to fetch packages", err);
  }
}

function* deleteClassroom(request) {
  try {
    const { firebase, classId } = request.payload;
    let endpoint = `bookingApi/classroom/${classId}`
    let response = yield call(callApi, firebase, "delete", endpoint, {});
    if (response.status == 200) {
      notification("success", response.body.message)
      yield put({
        type: actions.SETTING_SAGA_SUCCESS,
        payload: { isLoading: false, operationType: "FETCH_CLASSROOMS" }
      });
    }
    else {
      yield put({ type: actions.SETTING_SAGA_FAILED });
    }
  } catch (err) {
    yield put({
      type: actions.SETTING_SAGA_FAILED
    });
    console.log("failed to fetch packages", err);
  }
}

function* addSettings(request) {
  try {
    const { firebase, reqObj, settingType } = request.payload;
    let endpoint = `bookingApi/${settingType}`
    let response = yield call(callApi, firebase, "post", endpoint, reqObj);
    if (response.status == 200) {
      notification("success", response.body.message)
      yield put({
        type: actions.SETTING_SAGA_SUCCESS,
        payload: { isLoading: false, operationType: settingType }
      });
    }
    else {
      yield put({ type: actions.SETTING_SAGA_FAILED });
    }
  } catch (err) {
    yield put({
      type: actions.SETTING_SAGA_FAILED
    });
    console.log("failed to fetch packages", err);
  }
}

function* deleteSettings(request) {
  try {
    const { firebase, itemId, settingType } = request.payload;
    let endpoint = `bookingApi/${settingType}/${itemId}`
    let response = yield call(callApi, firebase, "delete", endpoint, {});
    if (response.status == 200) {
      notification("success", response.body.message)
      yield put({
        type: actions.SETTING_SAGA_SUCCESS,
        payload: { isLoading: false, operationType: settingType }
      });
    }
    else {
      yield put({ type: actions.SETTING_SAGA_FAILED });
    }
  } catch (err) {
    yield put({
      type: actions.SETTING_SAGA_FAILED
    });
    console.log("failed to fetch packages", err);
  }
}

function* confirmSettings(request) {
  try {
    const { firebase, reqObj, settingType } = request.payload;
    let endpoint = `bookingApi/settings`
    if (reqObj.id) {
      endpoint = endpoint + "/" + reqObj.id
    }
    let response = yield call(callApi, firebase, "post", endpoint, reqObj);
    if (response.status == 200) {
      notification("success", response.body.message)
      yield put({
        type: actions.SETTING_SAGA_SUCCESS,
        payload: { isLoading: false, operationType: settingType }
      });
    }
    else {
      yield put({ type: actions.SETTING_SAGA_FAILED });
    }
  } catch (err) {
    yield put({
      type: actions.SETTING_SAGA_FAILED
    });
    console.log("failed to set settings", err);
  }
}

function* getUserSettings(request) {
  try {
    const { firebase } = request.payload;
    let endpoint = `bookingApi/settings/user`
    let response = yield call(callApi, firebase, "get", endpoint, {});
    if (response.status == 200) {
      yield put({
        type: actions.SETTING_SAGA_SUCCESS,
        payload: { isLoading: false, userSetting: response.body.data }
      });
    }
    else {
      yield put({ type: actions.SETTING_SAGA_FAILED });
    }
  } catch (err) {
    yield put({
      type: actions.SETTING_SAGA_FAILED
    });
    console.log("failed to fetch settings", err);
  }
}
function* getGuestSettings(request) {
  try {
    const { firebase } = request.payload;
    let endpoint = `bookingApi/settings/guest`
    let response = yield call(callApi, firebase, "get", endpoint, {});
    if (response.status == 200) {

      yield put({
        type: actions.SETTING_SAGA_SUCCESS,
        payload: { isLoading: false, guestSetting: response.body.data }
      });
    }
    else {
      yield put({ type: actions.SETTING_SAGA_FAILED });
    }
  } catch (err) {
    yield put({
      type: actions.SETTING_SAGA_FAILED
    });
    console.log("failed to fetch settings", err);
  }
}

function* createStudentCustomField(request) {
  try {
    const { firebase, reqObj } = request.payload;
    let endpoint = `bookingApi/settings/studentCustomField`
    let response = yield call(callApi, firebase, "post", endpoint, reqObj);
    if (response.status == 200) {
      notification("success", reqObj.id ? "Custom field edited successfully" : "Custom field added successfully")
      yield put({
        type: actions.SETTING_SAGA_SUCCESS,
        payload: { isLoading: false, operationType: "fieldadded" }
      });
    }
    else {
      yield put({ type: actions.SETTING_SAGA_FAILED });
    }
  } catch (err) {
    yield put({
      type: actions.SETTING_SAGA_FAILED
    });
    console.log("failed to add student custom fields", err);
  }
}

function* getCustomFields(request) {
  try {
    const { firebase, fieldType } = request.payload;
    let endpoint = `bookingApi/settings/studentCustomField/${fieldType}`
    let response = yield call(callApi, firebase, "get", endpoint, {});
    if (response.status == 200) {
      yield put({
        type: actions.SETTING_SAGA_SUCCESS,
        payload: { isLoading: false, customFields: response.body.data }
      });
    }
    else {
      yield put({ type: actions.SETTING_SAGA_FAILED });
    }
  } catch (err) {
    yield put({
      type: actions.SETTING_SAGA_FAILED
    });
    console.log("failed to fetch custom fields", err);
  }
}

function* deleteCustomField(request) {
  try {
    const { firebase, fieldId } = request.payload;
    let endpoint = `bookingApi/settings/studentCustomField/${fieldId}`
    let response = yield call(callApi, firebase, "delete", endpoint, {});
    if (response.status == 200) {
      notification("success", response.body.message)
      yield put({
        type: actions.SETTING_SAGA_SUCCESS,
        payload: { isLoading: false, operationType: "fielddeleted" }
      });
    }
    else {
      yield put({ type: actions.SETTING_SAGA_FAILED });
    }
  } catch (err) {
    yield put({
      type: actions.SETTING_SAGA_FAILED
    });
    console.log("failed to delete field", err);
  }
}

export default function* rootSaga() {
  yield takeLatest(actions.SETTING_ADD_CLASSROOM, addClassroom)
  yield takeLatest(actions.SETTING_DELETE_CLASSROOM, deleteClassroom)
  yield takeLatest(actions.SETTING_ADD_SETTINGS, addSettings)
  yield takeLatest(actions.SETTING_DELETE_SETTINGS, deleteSettings)
  yield takeLatest(actions.SETTING_CONFIRM_SETTINGS, confirmSettings)
  yield takeLatest(actions.SETTING_GET_USER_SETTINGS, getUserSettings)
  yield takeLatest(actions.SETTING_GET_GUEST_SETTINGS, getGuestSettings)
  yield takeLatest(actions.SETTINGS_CREATE_STUDENT_CUSTOMFIELDS, createStudentCustomField)
  yield takeLatest(actions.SETTINGS_GET_CUSTOMFIELDS, getCustomFields)
  yield takeLatest(actions.SETTINGS_DELETE_CUSTOMFIELD, deleteCustomField)
}