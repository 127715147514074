import { all } from "redux-saga/effects";
import loginSaga from "./login/saga";
import packageSaga from "./package/saga";
import studentSaga from "./students/saga";
import staffSaga from "./staffs/saga";
import bookingActivity from './bookingActivity/saga';
import invoiceSaga from "./invoice/saga";
import calendarSaga from "./calendar/saga";
import parentSaga from "./parent/saga";
import settingSaga from "./setting/saga";
import notificationSaga from "./notification/saga";
import dashboardSaga from "./dashboard/saga";
import multicenterSaga from './multicenter/saga'; 


export default function* rootSaga(getState) {
	yield all([
		loginSaga(),
		packageSaga(),
		bookingActivity(),
		studentSaga(),
		staffSaga(),
		invoiceSaga(),
		calendarSaga(),
		parentSaga(),
		settingSaga(),
		notificationSaga(),
		dashboardSaga(),
		multicenterSaga() 
	]);
}