import "firebase/compat/storage";
import { call, takeLatest, put } from "redux-saga/effects";
import { callApi } from "../../util/superAgentUtil";
import actions from "../actionType";
import { notification } from "../../util/notification";
import formatMsg from "../../util/formatMsg";
import Helper from "../../util/helper";


function* getAllCentersList(actionObj: Record<string, any>) {
  try {

    const { firebase } = actionObj.payload;

    let endpoint = `bookingApi/multicenter?getType=getLowercasedCenterNamesArrAlso`;

    let response = yield call(callApi, firebase, "get", endpoint, {}, false, false, true);

    if (response.status == 200) {

      const lowercasedCenterNamesSet = new Set(response.body.data.lowercasedCenterNamesArray);
      const allCentersList = response.body.data.allCentersList;

      const centerIdToCenterObjMap = new Map();

      allCentersList.forEach((centerObj: Record<string, any>): void => {
        centerIdToCenterObjMap.set(centerObj.id, centerObj);
      });

      yield put({
        type: actions.MULTICENTER_SAGA_SUCCESS,
        payload: {
          isLoading: false,
          allCentersList,
          lowercasedCenterNamesSet,
          centerIdToCenterObjMap,
          operationType: 'GOT_ALL_CENTERS_LIST'
        }
      });
    }
    else {
      yield put({ type: actions.MULTICENTER_SAGA_FAILED });
      notification('error', formatMsg(`oopsSomthingWentWrong`));
    }
  }
  catch (err) {
    Helper.notifyBugsnag(err, true);
    yield put({ type: actions.MULTICENTER_SAGA_FAILED });
    console.log("failed to fetch all centers list", err);
  }
}


function* saveCenter(actionObj: Record<string, any>) {
  try {

    const { centerObj, firebase } = actionObj.payload;

    let storagePath = firebase.sbp + "/media/images/";
    if (centerObj.logoUrl && typeof centerObj.logoUrl !== 'string') {
      let logoUrl = yield call(Helper.getAttachedMediaPath, storagePath, centerObj.logoUrl, firebase);
      if (logoUrl) {
        centerObj.logoUrl = logoUrl[0].path;
      }
    }

    let endpoint = `bookingApi/multicenter/saveCenter`;
    let response = yield call(callApi, firebase, "post", endpoint, centerObj);

    if (response.status == 200) {
      yield put({
        type: actions.MULTICENTER_SAGA_SUCCESS,
        payload: {
          isLoading: false, 
          operationType: (centerObj.id) ? 'CENTER_UPDATED' : 'CENTER_ADDED'
        }
      });
      notification('success', formatMsg(`center${centerObj.id ? 'Updated' : 'Added'}Successfully`));
    }
    else {
      yield put({ type: actions.MULTICENTER_SAGA_FAILED });
      notification('error', formatMsg(`oopsSomthingWentWrong`));
    }
  }
  catch (err) {
    Helper.notifyBugsnag(err, true);
    yield put({ type: actions.MULTICENTER_SAGA_FAILED });
    console.log(`failed to ${actionObj.payload?.centerObj?.id ? 'update' : 'add'} center`, err);
  }
}


function* deleteCenter(actionObj: Record<string, any>) {
  try {

    const { id, firebase } = actionObj.payload;

    let endpoint = `bookingApi/multicenter/deleteCenter/${id}`;
    let response = yield call(callApi, firebase, "delete", endpoint, {});

    if (response.status == 200) {
      yield put({
        type: actions.MULTICENTER_SAGA_SUCCESS,
        payload: {
          isLoading: false,
          operationType: 'CENTER_DELETED'
        }
      });
      notification('success', formatMsg(`centerDeletedSuccessfully`));
    }
    else {
      yield put({ type: actions.MULTICENTER_SAGA_FAILED });
      notification('error', formatMsg(`oopsSomthingWentWrong`));
    }
  }
  catch (err) {
    Helper.notifyBugsnag(err, true);
    yield put({ type: actions.MULTICENTER_SAGA_FAILED });
    console.log("failed to fetch all centers list", err);
  }
}


function* getCentersListOfSinglePackage (actionObj: Record<string, any>) {
  try {

    const { packageId, firebase } = actionObj.payload; 

    let endpoint = `bookingApi/multicenter/centersListOfSinglePackage/${packageId}`; 
    let response = yield call(callApi, firebase, "get", endpoint, {}); 

  
    if (response.status == 200) {

      const centersListOfCurrPackageInView = response.body.data.centersListOfSinglePackage; 

      yield put({
        type: actions.MULTICENTER_SAGA_SUCCESS, 
        payload: {
          isLoading: false, 
          centersListOfCurrPackageInView, 
          operationType: 'GOT_CENTERS_OF_PACKAGE' 
        }
      });
    }
    else {
      yield put({ type: actions.MULTICENTER_SAGA_FAILED });
      notification('error', formatMsg(`oopsSomthingWentWrong`));
    }
  }
  catch (err) {
    Helper.notifyBugsnag(err, true);
    yield put({ type: actions.MULTICENTER_SAGA_FAILED });
    console.log("failed to fetch all centers list", err);
  }
}


export default function* rootSaga() {
  yield takeLatest(actions.GET_ALL_CENTERS_LIST, getAllCentersList);
  yield takeLatest(actions.SAVE_CENTER, saveCenter);
  yield takeLatest(actions.DELETE_CENTER, deleteCenter);
  yield takeLatest(actions.GET_CENTER_LIST_OF_SINGLE_PACKAGE, getCentersListOfSinglePackage);
}